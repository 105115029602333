<template>
  <App title="Orders"
       left-arrow
       class="orderList">
    <!-- <DropdownMenu slot="extra">
      <DropdownItem v-model="options.status"
                    :options="orderStatusOptions"
                    @change="onRefresh" />
    </DropdownMenu> -->
    <PullRefresh v-model="loading"
                 @refresh="onRefresh">
      <List v-model="loading"
            :finished="finished"
            :error.sync="error"
            :finished-text="total === 0 ? '暂无数据!' : 'No more items.!'"
            error-text="数据加载失败! 点击重试"
            @load="onLoad">
        <CardItem v-for="$item in dataList"
                  :key="$item.id"
                  :cardInfo="$item" />
      </List>
    </PullRefresh>
  </App>
</template>
<script>
import { List, PullRefresh, DropdownMenu, DropdownItem, Toast } from 'vant'
import { orderList } from '@/api/chargequery'
import CardItem from './components/card/index'
import dayjs from 'dayjs'
import { orderStatusAttr } from '../../utils/config'

export default {
  name: 'orderList',
  components: {
    CardItem,
    List,
    PullRefresh,
    // DropdownMenu,
    // DropdownItem
  },
  data () {
    return {
      loading: false,
      finished: false,
      error: false,
      total: 0,
      page: {
        index: 1,
        size: 10
      },
      options: {
        status: ''
      },
      dataList: []
    }
  },
  computed: {
    orderStatusOptions () {
      const res = [{ text: '全部', value: '' }]
      for (const key in orderStatusAttr) {
        res.push({ text: orderStatusAttr[key], value: key })
      }
      return res
    }
  },
  methods: {
    onLoad () {
      if (this.finished) return
      this.getOrderList()
    },
    onRefresh () {
      this.page.index = 1
      this.finished = false
      this.getOrderList(true)
    },
    getOrderList (refresh = false) {
      this.loading = true
      const params = this.formatRequestParams()
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      orderList(params).then(res => {
        Toast.clear()
        if (res.success) {
          res.data.pgdataList.forEach(item => {
            item.info = [
              { label: 'name', name: 'Buyer Name', val: item.name },
              { label: 'mobile', name: 'Buyer Phone Number', val: item.mobile },
              { label: 'city', name: 'City', val: item.cityName },
              { label: 'createTime', name: 'Created Time', val: dayjs(item.createdTime * 1000).format('YYYY-MM-DD HH:mm:ss') }
            ]
            item._status = orderStatusAttr[item.status]
          })
          this.dataList = refresh ? res.data.pgdataList : [...this.dataList, ...res.data.pgdataList]
          this.total = res.data.page.totalResult
          if (this.dataList.length === this.total) {
            this.finished = true
          } else {
            this.page.index++
          }
          this.error = false
        } else {
          this.error = true
          Toast('数据加载失败!')
        }
      }).catch(() => {
        Toast.clear()
        console.log('catch err')
        this.error = true
      }).finally(() => {
        Toast.clear()
        this.loading = false
      })
    },
    formatRequestParams () {
      const params = {
        channel: this.$storage.get('channel')
        // channel: 'SAW'
      }
      if (this.options.status) {
        params.status = this.options.status
      }
      // 数据隔离逻辑
      const roleCode = this.$storage.get('roleCode')
      if (roleCode === 'RE010') {
        // 出行顾问
        params.makeEmpIdList = [
          { makeEmpId: this.$storage.get('userId') }
        ]
      } else if (roleCode === 'RE006') {
        // 出行主管
        const makeEmpList = this.$storage.get('makeEmpList')
        if (makeEmpList) {
          params.makeEmpIdList = makeEmpList.map(item => ({ makeEmpId: item.idmUserid }))
        }
      } else if (['RE005', 'RE004', 'RE003'].includes(roleCode)) {
        // 店长 小区经理 大区经理
        const saleDlr = this.$storage.get('userStore')
        if (saleDlr) {
          params.saleDlrIDList = saleDlr.map(item => ({ saleDlrID: item.originOrgId }))
        }
      }

      return {
        page: this.page,
        ...params
      }
    }
  }
}
</script>